.edit-listing-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .edit-listing-title {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .input-field,
  .textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .textarea {
    resize: vertical;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  
  .save-button,
  .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .save-button {
    background-color: #4CAF50; /* Green */
    color: white;
    margin-right: 20px;
  }
  
  .save-button:hover {
    background-color: #45a049;
    margin-left: 20px;
  }
  
  .cancel-button {
    background-color: #f44336; /* Red */
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f;
  }
  
  /* EditListing.css */

/* Container for the existing images */
.existing-images {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  /* Styling for each image item */
  .image-item {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
  }
  
  .listing-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Delete button for existing images */
  .delete-image {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .delete-image:hover {
    background-color: #d9363e;
  }
  
  /* Container for new image previews */
  .new-images-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
  }
  
  /* Styling for each new image preview */
  .new-image-item {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
  }
  
  .new-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Delete button for new image previews */
  .new-image-item .delete-image {
    position: absolute;
    top: 60px;
    right: 2px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .new-image-item .delete-image:hover {
    background-color: #d9363e;
  }
  
  /* Styling for Save and Cancel buttons */
  .save-button, .cancel-button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #4caf50;
    color: white;
    margin-right: 10px;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f;
  }
  