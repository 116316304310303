/* General Form Styling */
form {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    align-items: center;
  }
  
  /* CreateListing.css */
.label-class {
  font-weight: light;
  color: #50566b;
  margin-top: 5px;
  margin-bottom: -7px;
  display: block;
}

  form select {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  form input,
  form textarea {
    width: 97%;
    padding: 12px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  form select:focus,
  form input:focus,
  form textarea:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
  }
  
  form label {
    font-size: 14px;
    color: #50566b;
    margin-bottom: 6px;
    display: block;
  }
  
  .checkbox-group-documents{
    font-size: small;
    padding: 20px;
  }
  form button {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s;
  }
  
  form button:hover {
    background-color: #0056b3;
  }
  
  .checkbox-group-documents {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;
  }
  
  .checkbox-group-documents label {
    align-items: left;
    font-size: 14px;
    margin-right: 10px;
  }
  
  .checkbox-group-documents input[type="checkbox"] {
    margin-right: 1px;
    
    transform: scale(1.2);
  }
  
  .checkbox-group-documents input[type="checkbox"]:checked {
    accent-color: #007bff;
  }
  
  /* File input styling */
  input[type="file"] {
    border: none;
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  input[type="file"]::file-selector-button {
    padding: 10px 15px;
    background-color: #e1e4e7;
    color: rgb(70, 66, 66);
    border-color: #343536;
    border-radius: 5px;
    cursor: pointer;
  }
  
  input[type="file"]::file-selector-button:hover {
    background-color: #0056b3;
  }
  
  textarea {
    height: 150px;
    resize: none;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    form {
      padding: 15px;
    }
  
    form input,
    form select,
    form textarea {
      font-size: 14px;
    }
  
    form button {
      width: 80%;
      font-size: 14px;
    }
  
    .checkbox-group-documents label {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    form {
      padding: 10px;
    }
  
    form input,
    form select,
    form textarea {
      font-size: 13px;
      width: 90%;
    }
  
    .checkbox-group-documents {
      flex-direction: column;
    }
  }
  