
/* Main admin panel container */
.admin-panel {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Title */
.admin-title {
    text-align: center;
    margin-bottom: 20px;
    color: #007BFF; /* Primary color */
}

.admin-details {
    text-align: center;
    background-color: #e3fce9;
    border-radius: 5px;
}


/* Authentication section */
.auth-section {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.auth-button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.auth-button:hover {
    background-color: #0056b3;
}

/* Modals */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 90%;
    max-width: 400px;
}


.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 1.5em;
}

/* Dashboard section */
.admindashboard {
    margin: 20px;
    align-items: center;
    text-align: center;
}

/* Container for the logout button */
.logout-container {
    display: flex;
    justify-content: flex-end; /* Aligns the button to the right */
    margin-top: -75px; /* Optional: space from top */
}
/* Logout button */
.logout-button {
    background-color: #dc3545; /* Danger color */
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;

    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.logout-button:hover {
    background-color: #c82333;
}



/* Listings styles */

.listings-preview {
    padding: 10px;
    text-align: center;
  }
  
  .listings-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between cards */
    color: #1f68bd;
    max-width: 380px;
  }
  
  .listing-item {
    background: #fff; /* White background for the card */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.178); /* Shadow for depth */
    overflow: hidden; /* Prevents image overflow */
    transition: transform 0.3s; /* Smooth scaling on hover */
    padding: 15px;
  
  }
  .listing-card a {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Ensures the text color is inherited, maintaining a clean look */
    
  }

  .listing-card:hover {
    transform: scale(1.02); /* Scale effect on hover */
  }
  
  .listing-card h4 {
    font-size: 1.5rem; /* Title size */
    margin: 10px 0; /* Spacing */
  }

  .image-container1 {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .image-container1 img {
    width: 100%;
    height: 200px;
    display: block;;
    object-fit: cover; /* Crop image appropriately */
  }
  
  .listing-type {
    position: absolute;
    top: 1px; /* Adjust the position */
    left: 10px; /* Adjust the position */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  

  .new-label {
    position: absolute;
    top: 1px; /* You can adjust this */
    right: 10px; /* Position it on the opposite side of the listing-type */
    background-color: rgba(4, 207, 72, 0.459); /* Red semi-transparent background */
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  
  
  .listing-price {
    color: #09bd21; /* Price color */
    font-size: 1.2rem; /* Price size */
    font-weight: bold; /* Price weight */
  }
  
  .listing-details,
  .listing-location,
  .listing-date {
    font-size: 0.9rem; /* Smaller text for details */
    color: #555; /* Gray text color */
    margin: 5px 0; /* Spacing */
  }


/* Edit button */
.edit-butn {
    background-color: #93a1b1; /* Danger color */
    color: #fff;
    border: none;
    width: 70px;
    margin-right: 49px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-butn:hover {
    background-color: #025ab8;
}

/* Delete button */
.delete-btn {
    background-color: #dc3545; /* Danger color */
    color: #fff;
    border: none;
    width: 70px;
    margin-left: 49px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.delete-btn:hover {
    background-color: #ad0516;
}

.cancel-delete-btn {
    background-color: #007BFF; /* Danger color */
    color: #fff;
    border: none;
    width: 70px;
    margin-left: 49px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.cancel-delete-btn:hover {
    background-color: #025ab8;
}

/* Delete button */
.confirm-delete-btn {
    background-color: #dc3545; /* Danger color */
    color: #fff;
    border: none;
    width: 70px;
    margin-right: 49px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.confirm-delete-btn:hover {
    background-color: #ad0516;

}
/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .auth-section {
        flex-direction: column;
        align-items: center;
    }
    
    .auth-button {
        width: 100%;
        margin-bottom: 10px;
    }

    .modal-content {
        width: 90%;
    }
}

@media (max-width: 480px) {
.listing-card h4 {
    font-size: 1.2rem; /* Title size */
    margin: 10px 0; /* Spacing */
  }

  .listing-price {
    font-size: 1rem;
    font-weight: bold;
    color: #3498db;
  }
}

