/* General Page Styling */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f6fa;
    color: #333;
    margin: 0;
    padding: 0;
}
  
.listings-page {
    max-width: 98%;
    padding: 10px;
}

h1 {
  text-align: center;
  font-size: 1.5rem;
  color: #2f3640;
  margin-bottom: 20px;
}

/* Sorting Section Styling */
.sorting-section {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.sorting-bar {
  display: flex;
  gap: 15px;
  align-items: center;
}

.sorting-bar label {
  font-weight: bold;
}

.sorting-bar select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

/* Listings Grid Styling */
.listings-container2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 280px;
  text-align: center;
}

/* Individual Listing Card */
.listing-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.listing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.listing-card a {
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 20px;
}

.image-container1 {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-bottom: 2px solid #f0f0f0;
}

.image-container1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-container1 img:hover {
  transform: scale(1.1);
}

.listing-type {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #686e6b73;
  color: white;
  padding: 5px 10px;
  font-size: 0.9rem;
  border-radius: 5px;
}

.new-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #13a052;
  color: white;
  padding: 5px 10px;
  font-size: 0.9rem;
  border-radius: 5px;
}

h4 {
  font-size: 1.25rem;
  color: #2c3e50;
  margin: 15px 0 10px;
}

.listing-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #3498db;
}

.listing-details {
  font-size: 0.9rem;
  margin: 5px 0;
  color: #7f8c8d;
}

.listing-location {
  font-size: 0.9rem;
  color: #95a5a6;
}

.listing-date {
  font-size: 0.85rem;
  color: #bdc3c7;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .listings-container {
    grid-template-columns: 1fr;
  }

  h1 {
    text-align: center;
    font-size: 1rem;
    color: #2f3640;
    margin-bottom: 10px;
  }
  
  /* Sorting Section Styling */
  .sorting-section {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .sorting-bar {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .sorting-bar label {
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .listing-price {
    font-size: 1rem;
    font-weight: bold;
    color: #3498db;
  }
}