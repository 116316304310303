/* Basic Header Styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  background-image: url("C:/Users/TovDev/Enddy-Nation-real-estate/frontend/src/images/Untitled (7).png");
  color: white;
  max-height: 60px;
}

.header .logo {
  position: relative;
}

.header .logo h1 {
  color: rgb(17, 13, 243);
  font-size: 2rem;
  text-shadow: 
    -2px -2px 0 #ffffff,  
     2px -2px 0 #ffffff,
    -2px  2px 0 #ffffff,
     2px  2px 0 #ffffff; /* White outline */
  margin: 0;
  position: relative;
}

.header .logo a {
  text-decoration: none; /* Remove underline from the link */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.rcnumber {
  position: absolute;
  top: -27px; /* Adjusts RC number above h1 */
  right: 0; /* Align to the right end of the h1 */
  color: #faf9f9;
  font-size: 14px;
  white-space: nowrap; /* Prevents wrapping */
}

/* Basic Navbar Styling */
.navbar {
  display: flex;
  align-items: center;
}

/* Nav links styling for larger screens */
.nav-links {
  display: none; /* Hidden by default on small screens */
  list-style: none;
  flex-direction: row; /* Horizontal on larger screens */
  background-color: transparent;
  gap: 20px;
}

/* Backdrop styles */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99; /* Behind the navbar but over the rest of the content */
}
/* Media query for responsive text size */
@media (max-width: 768px) {
  .header .logo h1 {
    max-height: 60px;
    font-size: 6vw; /* Adjust for tablets and smaller screens */
  }

  .rcnumber {
    font-size: 12px; /* Adjust font size on smaller screens */
    top: -20px; /* Adjust RC number position */
  }
}

@media (max-width: 480px) {
  .header .logo h1 {
    max-height: 60px;
    font-size: 5vw; /* Further adjust for mobile screens */
  }

  .rcnumber {
    font-size: 12px;
    top: -23px; /* Adjust RC number position for mobile */
  }
}

/* Basic Navbar Styling */
.navbar {
  display: flex;
  align-items: center;
}

/* Nav links styling for larger screens */
.nav-links {
  display: none; /* Hidden by default on small screens */
  list-style: none;
  flex-direction: row; /* Horizontal on larger screens */
  background-color: transparent;
  gap: 20px;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

/* Remove horizontal overflow from the entire page */
html, body {
  overflow-x: hidden; /* Prevents horizontal scroll */
}

/* Hamburger icon styling */
.hamburger {
  display: none; /* Hidden by default */
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  z-index: 1004;
  margin-right: 10px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease; /* Animate the transition */
}

/* Hamburger active state when sidebar is open */
.hamburger.open .bar1 {
  transform: rotate(45deg) translate(5px, 5px); /* Rotate to top right */
}

.hamburger.open .bar2 {
  opacity: 0; /* Hide the middle bar */
}

.hamburger.open .bar3 {
  transform: rotate(-45deg) translate(5px, -5px); /* Rotate to bottom right */
}

/* Sidebar styles for smaller screens */
.nav-links {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 250px;
  font-size: 15px;
  text-align: left;
  background-color: #5f645fef;
  color: #ffffff;
  transform: translateX(100%); /* Start off-screen */
  transition: transform 0.3s ease; /* Smooth slide in/out */
  padding: 1px;
  z-index: 1003;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.774);
}

.nav-links.open {
  transform: translateX(0); /* Slide in when open */
  margin-top: 2px;
  padding-top: 15px;
}

.nav-links li {
  margin-left: 20px;
  margin: 10px;
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Show hamburger icon on smaller screens */
  }

  .nav-links {
    display: flex; /* Show the nav-links as a sidebar */
    flex-direction: column;
  }


}

/* Media query for screens larger than 768px */
@media (min-width: 768px) {
  .nav-links {
    display: flex; /* Show nav links in a row */
    position: static; /* Static position, no sidebar */
    flex-direction: row; /* Horizontal layout */
    width: auto;
    height: auto;
    background-color: transparent;
    transform: none; /* No transformation */
  }

  .hamburger {
    display: none; /* Hide hamburger on larger screens */
  }
  
}