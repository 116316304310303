/* Container for the registration form */
.admin-register-container {
    max-width: 400px;
    margin: 30px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  /* Heading style */
  .admin-register-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Form styling */
  .admin-register-form {
    display: flex;
    flex-direction: column;
  }
  
  /* Individual form group styling */
  .form-group {
    margin-bottom: 15px;
  }
  
  /* Label styling */
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  /* Input field styling */
  .form-group input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  /* Input focus effect */
  .form-group input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Error message styling */
  .error {
    color: #d9534f;
    font-size: 14px;
  }
  
  /* Success message styling */
  .success {
    color: #5cb85c;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
  
  /* Button styling */
  button[type="submit"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Button hover effect */
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  /* Password container styling */
  .password-container {
    display: flex;
    align-items: center;
  }
  
  /* Toggle button styling */
  .toggle-password-btn {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    padding-left: 10px;
    font-size: 14px;
  }
  
  /* Toggle button hover effect */
  .toggle-password-btn:hover {
    text-decoration: underline;
  }
  