.footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 20px 20px;
  text-align: center;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}

.footer-left, .footer-right {
  text-align: left;
}

.footer-left h2, .footer-right h2 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: #f39c12;
}

.footer-left h3 {
  font-size: 1.1rem;
  margin-top: 15px;
  color: #ecf0f1;
}


.footer-left ul, .footer-right ul {
  list-style: none;
  padding: 0;
}

.footer-left ul li, .footer-right ul li {
  font-size: 0.9rem;
  margin-bottom: 5px;
  padding-left: 20px;
  
  z-index: 1;
}

.footer-left ul li::before, .footer-right ul li::before {
  content: "✔";
  color: #27ae60;
  font-weight: bold;
  
  left: 0;
}

.footer-bottom {
  margin-top: 20px;
}

.footer-bottom p {
  margin: 10px 0;
}

.social-media {
  margin-top: 10px;
}

.social-media a {
  color: #f1c40f;
  margin: 0 10px;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-media a:hover {
  color: #e74c3c;
}

@media screen and (max-width: 768px) {
  .footer-grid {
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 30px;
  }

  .footer-left, .footer-right {
    text-align: left;
  }

  .footer-left h2, .footer-right h2 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #f39c12;
  }
  
  .footer-left h3 {
    font-size: 0.9rem;
    margin-top: 15px;
    color: #ecf0f1;
  }
}
