/* ContactUs.css */
.contact-us {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  .contact-info {
    margin-bottom: 30px;
  }
  
  .contact-info h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #913602;
  }
  
  .contact-info p {
    font-size: 1.2rem;
    margin: 5px 0;
  }
  
  .contact-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .contact-form h2 {
    font-size: 1.6rem;
    margin-bottom: 20px;
    color: #02914e;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button-c[type="submit"] {
    background-color: #02914e;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button-c[type="submit"]:hover {
    background-color: #036135;
  }
  
  .thank-you-message {
    text-align: center;
    margin-top: 50px;
  }
  
  .thank-you-message h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .map-container {
    text-align: center;
    margin: 40px 0;
  }
  
  .map-container iframe {
    width: 100%;
    max-width: 100%;
    height: 400px;
    border-radius: 8px;
    border: none;
  }
  