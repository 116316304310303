@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


.homepage {
    height: auto;
    width: 100%; /* Prevents overflow */
    padding-right: 1;
    background-color: #fafffb;
  }
  .hero-section{
    height: auto;
    margin-bottom: 10px;
  }
  
  .slide-1 h2,
  .slide-2 h2,
  .slide-3 h2,
  .slide-4 h2 {
    color: #ff5ac0; /* Replace this with your desired color */
    font-size: 3rem; /* Default size for larger screens */
    font-weight: bold;
    margin-bottom: 15px;
    text-shadow: 3px 5px 2px rgba(0, 0, 0, 0.7); 
  }


  .slide-txt {
    font-size: 1.5rem;
    text-shadow: 3px 5px 2px rgba(0, 0, 0, 0.7); 
  }

  .slide-butn {
    padding: 10px 20px;
    background-color: #059c49;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 7px;
    transition: background-color 0.3s ease;
    box-shadow: 3px 5px 4px rgba(0, 0, 0, 0.7); 
  }

  button:hover {
    background-color: #007239;
  }

  button a {
    color: white;
    text-decoration: none;
  }

  /* Background images for slides */
  .slide-1, .slide-2, .slide-3, .slide-4 {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 100px 1px;
    height: 50vh;
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
  }


  /* Specific slide styling */
  .slide-1 {
    background-image: url("C:/Users/TovDev/Enddy-Nation-real-estate/frontend/src/images/HeroImage.jpg");
  
  }

  .slide-2 {
    background-image: url("C:\Users\TovDev\Enddy-Nation-real-estate\frontend\src\images\Plots-of-Land-For-Sale-in-Owerri-Imo-State.jpg");
  }

  .slide-3 {
    background-image: url("C:\Users\TovDev\Enddy-Nation-real-estate\frontend\src\images\Penthouse-master-bedroom.jpg");
  }

  .slide-4 {
    background-image: url("C:\Users\TovDev\Enddy-Nation-real-estate\frontend\src\images\Penthouse-master-bedroom.jpg");
  }


  .listings-preview {
    padding: 10px;
    text-align: center;
  }
  .all-listings button {
    background-color: transparent;
    
  }

  .all-listings a {
    
    color: black;
    
  }

  .listings-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
    gap: 20px; /* Space between cards */
    max-width: 550px;
  }
  
  .listing-card {
    background: #fff; /* White background for the card */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    overflow: hidden; /* Prevents image overflow */
    transition: transform 0.3s; /* Smooth scaling on hover */
    padding: 1px;

  
  }
  .listing-card a {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Ensures the text color is inherited, maintaining a clean look */
    
  }

  .listing-card:hover {
    transform: scale(1.02); /* Scale effect on hover */
  }
  
  .listing-card h4 {
    font-size: 1.5rem; /* Title size */
    margin: 10px 0; /* Spacing */
  }

  .image-container1 {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .image-container1 img {
    width: 99%;
    height: 200px;
    display: block;;
    object-fit: cover; /* Crop image appropriately */
  }
  
  .listing-type {
    position: absolute;
    top: 1px; /* Adjust the position */
    left: 10px; /* Adjust the position */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  

  .new-label {
    position: absolute;
    top: 1px; /* You can adjust this */
    right: 10px; /* Position it on the opposite side of the listing-type */
    background-color: rgba(4, 207, 72, 0.459); /* Red semi-transparent background */
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
  }
  
  
  .listing-price {
    color: #09bd21; /* Price color */
    font-size: 1.2rem; /* Price size */
    font-weight: bold; /* Price weight */
  }
  
  .listing-details,
  .listing-location,
  .listing-date {
    font-size: 0.9rem; /* Smaller text for details */
    color: #555; /* Gray text color */
    margin: 5px 0; /* Spacing */
  }

  

/* Media queries to handle smaller screens */
@media (max-width: 768px) {
  
  .sorting-section {
    flex-direction: row; /* Stay in row direction even on small screens */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: flex-start;
  }


  .slide-1, .slide-2, .slide-3, .slide-4 {
    height: 20vh;
  }

  .slide-1 h2,
  .slide-2 h2,
  .slide-3 h2,
  .slide-4 h2{
      font-size: 2rem; /* Slightly smaller for tablets */
    }
  
    p {
    font-size: 1rem; /* Slightly smaller for tablets */
    }
}

@media (max-width: 480px) {
  
      
  .slide-1 h2,
  .slide-2 h2,
  .slide-3 h2,
  .slide-4 h2 {
    font-size: 1.3rem; /* Smaller font size for mobile */
  }

  .listing-card h4 {
    font-size: 1.2rem; /* Title size */
    margin: 10px 0; /* Spacing */
  }

  .listing-price {
    font-size: 1rem;
    font-weight: bold;
    color: #3498db;
  }

    .slide-txt {
      font-size: 1.2rem;
      text-shadow: 3px 5px 2px rgb(0, 0, 0); 
      background-color: #08080859;
    }
  
    p {
      font-size: 0.9rem; /* Smaller font size for mobile */
    }
  
    button {
      font-size: 0.9rem;
      padding: 8px 15px;
    }
    
  }
  
