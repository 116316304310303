/* Cookies Popup Styles */
.cookie-popup {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    padding: 15px;
    background-color: #333;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
  }
  
  .cookie-popup p {
    margin: 0;
    padding-right: 10px;
  }
  
  .cookie-popup button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .cookie-popup button:hover {
    background-color: #45a049;
  }
  