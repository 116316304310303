/* General Styles for the Listing Detail Page */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.listing-detail {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.2;
}

.date-created {
  background-color: #019208;
  color: #ddd;
  font-size: 1rem;
  border-radius: 3px;
  margin-top: -20px;
  margin-bottom: 20px;
  max-width: 162px;
  z-index: 10009;
}

/* Title */
.listing-detail h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}
  
.image-gallery {
  margin-bottom: 30px;
}
  

.main-image img {
  width: 100%;          /* Ensures the image scales horizontally */
  height: auto;         /* Maintain the image aspect ratio */
  max-height: 80vh;     /* Optional: Limit the image height for better responsiveness */
  object-fit: contain;  /* Prevent cropping, scale the image properly */
}

  
.thumbnail img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
}
  
.slick-slide {
  outline: none;
}
  
.slick-dots li button:before {
  font-size: 12px;
  color: #333;
}
  
.slick-dots li.slick-active button:before {
  color: #000;
}
  
.slick-prev, .slick-next {
    
  font-size: 0; /* Hide default slick text */
  width: 40px;
  height: 40px;
  background: #000;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
  
.slick-prev:hover, .slick-next:hover {
  background: #444;
}
  

  
.details-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}


.details-group {
  padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-size: 1rem;
}
  
.details-group h3 {
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
  font-size: 1.3rem;
}
  
.details-group p {
  margin: 5px 0;
  font-size: 1rem;
}
  
.details-group ul {
  list-style-type: disc;
  padding-left: 20px;
}
  
.contact-heading {
  margin-bottom: 1rem;
  text-align: center;
  margin-bottom: -25px;
}

.contact {
  
  margin-top: 20px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-and-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
}

.contact-form {
  width: 450px;
  flex: 1;
  margin-right: 2rem;
}

form div {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
textarea {
  width: 97%;
  padding: 0.75rem;
  border: 1px solid #4b8357;
  border-radius: 5px;
  font-size: 1rem;
}

textarea {
  height: 60px;
  width: 97%;
}

input[type="checkbox"] {
  margin-right: 0.2rem;
  max-width: 20px;
}

button {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

/* Company Contact Details */
.company-contact-details {
  flex: 1;
  background-color: #f1f1f1;
  padding: 1rem;
  border-radius: 10px;
}

.company-contact-details h3 {
  margin-bottom: 1rem;
}

.company-contact-details p {
  margin-bottom: 0.5rem;
}

/* Responsive Styles */
@media (max-width: 700px) {
  .details-section {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .form-and-details {
    flex-direction: column;
  }

  .contact-form {
    width: 90%;
    flex: 1;
    margin-right: 2rem;
  }
  .company-contact-details {
    width: 90%;
    flex: 1;
    background-color: #f1f1f1;
    padding: 1rem;
    border-radius: 10px;
  }

  input[type="text"],
  input[type="email"],
  textarea {
  width: 90%;
  padding: 0.75rem;
  border: 1px solid #4b8357;
  border-radius: 5px;
  font-size: 1rem;
}
}