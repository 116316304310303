.admin-login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    z-index: 9999;
  }
   
  h2 {
    margin-bottom: 20px;
    font-size: 28px;
    color: #333;
  }
  
  .admin-login-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
    text-align: left;
    display: block;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: border-color 0.2s ease;
  }
  
  input:focus {
    outline: none;
    border-color: #3498db;
  }
  
  .password-wrapper {
    display: flex;
    align-items: center;
  }
  
  .show-password-btn {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #3498db;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  
  .show-password-btn:hover {
    background-color: #2980b9;
  }
  
  .login-btn {
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-btn:hover {
    background-color: #2980b9;
  }
  

  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .success-message {
    color: green;
    margin-bottom: 10px;
  }
  