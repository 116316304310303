.about-us {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.about-us h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

.about-us-content h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-top: 30px;
  border-bottom: 2px solid #3498db;
  padding-bottom: 5px;
}

.about-us-content h3 {
  font-size: 1.6rem;
  color: #34495e;
  margin-top: 20px;
}

.about-us-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 15px 0;
}

.about-us-content ul {
  list-style: none;
  padding: 0;
}

.about-us-content ul li {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

.about-us-content ul li::before {
  content: "✔";
  color: #27ae60;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.about-us-content a {
  color: #2980b9;
  text-decoration: none;
  font-weight: bold;
}

.about-us-content a:hover {
  text-decoration: underline;
}

.about-us-content strong {
  color: #2c3e50;
}

@media screen and (max-width: 768px) {
  .about-us {
    padding: 20px;
  }

  .about-us h1 {
    font-size: 2rem;
  }

  .about-us-content h2 {
    font-size: 1.8rem;
  }

  .about-us-content h3 {
    font-size: 1.4rem;
  }

  .about-us-content p,
  .about-us-content ul li {
    font-size: 1rem;
  }
}
