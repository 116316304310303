/* Container for the sorting bar */
.sorting-bar {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping if the screen gets too small */
  justify-content: space-between; /* Add spacing between sorting options */
  gap: 20px;
  padding: 10px 0;
}

/* Sorting option styles */
.sort-option {
  display: flex;
  flex-direction: column; /* Ensure labels and selects stack properly */
  align-items: flex-start;
}

/* Make sure select elements are responsive */
.sort-option select {
  padding: 5px;
  font-size: 1rem;
  max-width: 200px; /* Ensure the select boxes have a minimum width */
}

.reset-option button {
  padding: 8px 16px;
  background-color: #beafae; /* Red background */
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.reset-option button:hover {
  background-color: #9c9595; /* Darker red on hover */
}

/* Responsive scaling for smaller screens */
@media (max-width: 768px) {
  .sorting-bar {
    justify-content: center; /* Center sorting options on smaller screens */
    gap: 10px;
  }

  .reset-option button {
    margin-top: 25px;
  }
  

  .sort-option {
    flex: 1 1 auto; /* Allow the options to scale evenly on small screens */
    max-width: 100%; /* Ensure the sorting options take up the full width */
  }

  .sort-option select {
    width: 100%; /* Make select boxes take full width on small screens */
  }
}

/* Styling for larger screens */
@media (max-width: 480px) {
  .sorting-bar {
    flex-wrap: wrap; /* Prevent wrapping on larger screens */
    
  }

  .sort-option {
    flex-direction: row; /* Align labels and selects horizontally */
    align-items: center;
    justify-content: space-between;
  }

  .sort-option label {
    margin-right: 1px; /* Space between label and select */
    font-size: 12px;
  }
  .sort-option select {
    width: 120px; /* Make select boxes take full width on small screens */
    padding: 1px;
    font-size: 13px;
  }
}
